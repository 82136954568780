// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "trix"
import "@rails/actiontext"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('jquery')
require("packs/uikit-icons")
require("packs/uikit")
require("packs/ui")
//require("packs/spectrum")


import flatpickr from 'flatpickr'
require("flatpickr/dist/flatpickr.css");

import UIkit from 'packs/uikit'
window.UIkit = UIkit

//const UIkit = require('packs/uikit')
const Icons = require('packs/uikit-icons')

UIkit.use(Icons);

document.addEventListener('turbolinks:load', () => {
  flatpickr(".flatpickr", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
  
  flatpickr(".flatpickr_month", {
    	dateFormat: "m/Y",
	    altFormat: "F Y",
    });
})

document.addEventListener('turbolinks:load', () => {


  //Submit Edit Brief
  // $('#save_brief_btn').click(function(){
  //   $('#brief_form').submit();
  // })

  if($('.toggle_hide_btn').length){
    
    $('.toggle_hide_btn').on('click', function(){
      var b = $(this).parent().next().val();
      console.log(b)
      if(b == "true") { var n = false } else { var n = true};
      console.log(n);
      var s = $(this).parent().next('.section_hide_boolean');
      $(s).attr('value', n);
      console.log(s);
      
      $(this).toggleClass('section_off');
      $(this).parent('.form_label').find('.label_txt').toggleClass('strikethrough');
      
    })
    
  }
  
  var $del_modal = $('#add_deliverable_modal');
  $del_modal.on("beforeshow", function() {
    //var completion = $('#brief_completion').val();
    //console.log(completion);
    //$('#deliverable_required_by').attr('value', completion)
    
    
    flatpickr("#brief_completion", {
        onChange: function(dateObj, dateStr) {
            //console.log(dateObj);
            //console.log(dateStr);
            flatpickr("#deliverable_required_by").setDate(dateObj);
        }
    });
    
    
   });
  
})



require("trix")
require("@rails/actiontext")