document.addEventListener('turbolinks:load', function(){

	innit()
	

})

function innit(){
	
	
	if($('#brief_form').length){
		autosave_brief()
	}
}

function autosave_brief(){
	
setInterval(function() {
    //console.log('autosave');
    $('#brief_form')[0].requestSubmit()
}, 60 * 1000);

}
